<template>
  <div id="e-course-info">
    <div class="columns is-multiline">
      <div class="column is-8 is-offset-2">
        <p class="title is-5 has-text-right">{{ schoolClassName }}</p>
        <p class="subtitle is-6 has-text-right">
          Course name: {{ subjectName }}
        </p>
      </div>
      <div class="column is-8 is-offset-2">
        <p class="title is-6">Topics</p>
        <ul class="topics">
          <li v-for="eCourseTopic in eCourseTopics" :key="eCourseTopic.id">
            <div class="topic">
              <div class="topic-item has-background-white-ter">
                <p class="title is-5">{{ eCourseTopic.name }}</p>
              </div>
              <div class="topic-footer">
                <a
                  class="is-small has-text-info has-text-left"
                  @click="fetchECourseWorks(eCourseTopic)"
                  style="font-size: 0.8em; margin-left: 16px"
                  >E Course Works</a
                >
                <a
                  class="is-small has-text-success"
                  @click="initializeUpdateECourseTopic(eCourseTopic)"
                  style="font-size: 0.9em; margin-left: 16px"
                >
                  <fa-icon icon="edit" size="lg" />
                </a>
                <a
                  class="is-small has-text-danger"
                  @click="initializeDeleteECourseTopic(eCourseTopic)"
                  style="font-size: 0.9em; margin-left: 16px"
                >
                  <fa-icon icon="trash-alt" size="lg" />
                </a>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div class="column is-8 is-offset-2">
        <div class="buttons is-right">
          <b-button
            type="is-primary"
            outlined
            @click="initializeCreateECourseTopic()"
            >Add Topic</b-button
          >
        </div>
      </div>
    </div>
    <app-modal
      :modal-title="pageTitle"
      :context="context"
      width="500px"
      @add="createTopic"
      @update="updateTopic"
      @remove="removeTopic"
      :show-modal.sync="isModalOpen"
    >
      <template v-slot:add>
        <SdInput
          type="text"
          rules="required"
          label="Topic"
          v-model="eCourseTopic.name"
          placeholder="Enter a topic"
        />
      </template>
      <template v-slot:remove></template>
    </app-modal>
  </div>
</template>

<script>
import gql from 'graphql-tag'
import { fetchUser } from '@/assets/js/app_info.js'
import SdInput from '../../components/SdInput'
import SdSelect from '../../components/SdSelect'
import { crudNotification } from '../../assets/js/notification'
import { ValidationObserver } from 'vee-validate'

export default {
  data() {
    return {
      pageTitle: 'E Course Info',
      user: null,
      schoolId: null,
      skipQuery: true,
      eCourse: {},
      eCourseTopics: [],
      context: 'add',
      activeTab: 0,
      eCourseTopic: {
        id: null,
        name: null,
        eCourseWorks: [],
      },
      eCourseWorkId: null,
      eCourseWorks: [],
      remove: null,
      schoolClassName: null,
      subjectName: null,
      isModalOpen: false,
    }
  },
  watch: {
    eCourse(data) {
      this.eCourseTopics = data.eCourseTopics
      this.schoolClassName = data.schoolClass.name
      this.subjectName = data.subject.name
    },
  },
  methods: {
    initializeCreateECourseTopic() {
      this.context = 'add'
      this.isModalOpen = true
    },
    initializeUpdateECourseTopic(eCourseTopic) {
      this.context = 'update'
      this.eCourseTopic = eCourseTopic
      this.isModalOpen = true
    },
    initializeDeleteECourseTopic(eCourseTopic) {
      this.context = 'remove'
      this.remove = 'ECourseTopic'
      this.eCourseTopic = eCourseTopic
      this.isModalOpen = true
    },
    fetchECourseWorks(eCourseTopic) {
      this.$router.push(
        `/school/${this.schoolId}/e_course_works/${eCourseTopic.id}`
      )
    },
    fetchECourseWork(eCourseTopic) {},
    fetchECourseWorkQuestions(eCourseWork) {
      this.$router.push(
        `/school/${this.schoolId}/e_course_info/${this.$route.params.id}/questions/${eCourseWork.id}`
      )
    },
    async createTopic(validate) {
      const valid = await validate()

      if (!valid) return
      // Add
      this.$apollo
        .mutate({
          mutation: gql`
            mutation CreateECourseTopic($eCourseId: Int!, $name: String!) {
              createECourseTopic(
                input: { eCourseId: $eCourseId, name: $name }
              ) {
                eCourseTopic {
                  id
                }
                errors
              }
            }
          `,
          // Parameters
          variables: {
            eCourseId: parseInt(this.$route.params.id),
            name: this.eCourseTopic.name,
          },
        })
        .then((response) => {
          crudNotification(
            response.data.createECourseTopic.errors,
            'Successfully created.'
          ).then(() => {
            this.isModalOpen = false
            this.$apollo.queries.eCourse.refetch()
          })
        })
        .catch((error) => {
          // console.error(error);
        })
    },
    updateTopic() {
      // Add
      this.$apollo
        .mutate({
          mutation: gql`
            mutation UpdateECourseTopic($id: Int!, $name: String!) {
              updateECourseTopic(input: { id: $id, name: $name }) {
                eCourseTopic {
                  id
                }
                errors
              }
            }
          `,
          // Parameters
          variables: {
            id: parseInt(this.eCourseTopic.id),
            name: this.eCourseTopic.name,
          },
        })
        .then((response) => {
          crudNotification(
            response.data.updateECourseTopic.errors,
            'Successfully updated.'
          ).then(() => {
            this.isModalOpen = false
            this.$apollo.queries.eCourse.refetch()
          })
        })
        .catch((error) => {
          // console.error(error);
        })
    },
    removeTopic() {
      if (this.remove === 'ECourseTopic') {
        this.$apollo
          .mutate({
            mutation: gql`
              mutation DeleteECourseTopic($id: Int!) {
                deleteECourseTopic(input: { id: $id }) {
                  eCourseTopic {
                    id
                  }
                  errors
                }
              }
            `,
            variables: {
              id: parseInt(this.eCourseTopic.id),
            },
          })
          .then((response) => {
            crudNotification(
              response.data.deleteECourseTopic.errors,
              'Successfully deleted.'
            ).then(() => {
              this.isModalOpen = false
              this.$apollo.queries.eCourse.refetch()
            })
          })
          .catch((error) => {
            // console.error(error);
          })
      } else {
        this.$apollo
          .mutate({
            mutation: gql`
              mutation DeleteECourseWork($id: Int!) {
                deleteECourseWork(input: { id: $id }) {
                  eCourseWork {
                    id
                  }
                  errors
                }
              }
            `,
            variables: {
              id: parseInt(this.eCourseWorkId),
            },
          })
          .then((response) => {
            const data = response.data.deleteECourseWork
            crudNotification(data.errors, 'Successfully deleted.').then(() => {
              this.isModalOpen = false

              this.eCourseWorks = this.eCourseWorks.filter(
                (item) => item.id !== this.eCourseWorkId
              )
            })
          })
          .catch((error) => {
            console.error(error)
          })
      }
    },
  },
  components: {
    SdInput,
  },
  mounted() {
    this.skipQuery = false
    this.$store.commit('setPageTitle', this.pageTitle)
    fetchUser().then((user) => {
      this.user = user
      this.schoolId = user.school_id
      this.$store.commit('setSubMenus', [
        {
          name: 'E Courses',
          route: `/school/${user.school_id}/e_courses`,
        },
      ])
    })

    this.$apollo.addSmartQuery('eCourse', {
      query: gql`
        query ECourseInfoQuery($id: ID!) {
          eCourse(id: $id) {
            eCourseTopics {
              id
              name
              eCourseWorks {
                id
                courseType
                duration
                published
                questions
              }
            }
            schoolClass {
              id
              name
            }
            subject {
              id
              name
            }
          }
        }
      `,
      variables() {
        return { id: this.$route.params.id }
      },
    })

    this.$apollo.queries.eCourse.refetch()
  },
}
</script>

<style lang="scss" scoped>
.topic {
  display: flex;
  flex-direction: column;
  background-color: #eee;
  margin-top: 5px;
}

.topic-item {
  padding: 8px;
  min-height: 60px;
}

.topic-footer {
  display: flex;
  padding: 8px;
}

.topic-footer > a {
  flex: 1;
}

.topic-footer > a:nth-child(1) {
  flex: 2 75%;
}
</style>
